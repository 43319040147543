import { useAnimationControls } from 'framer-motion';
import React from 'react';

const useFadeOut = (open: boolean) => {
  const [canUnmount, setCanUnmount] = React.useState(!open);
  const controls = useAnimationControls();

  React.useEffect(() => {
    const hide = async () => {
      controls.set({ opacity: 1 });
      await controls.start({ opacity: 0 });
      setCanUnmount(true);
    };

    const show = async () => {
      setCanUnmount(false);
    };

    if (!open) {
      hide();
    } else {
      show();
    }
  }, [controls, open]);

  React.useEffect(() => {
    if (!canUnmount) {
      controls.start({ opacity: 1 });
    }
  }, [canUnmount, controls]);

  return React.useMemo(
    () => ({ canUnmount, controls }),
    [controls, canUnmount]
  );
};

export default useFadeOut;
