/* eslint-disable @next/next/no-img-element */
import clsx from 'clsx';
import { useAnimate } from 'framer-motion';
import Head from 'next/head';
import Link from 'next/link';
import React from 'react';
import { RiDiscordLine, RiTwitchLine } from 'react-icons/ri';

import { useIsMobile } from '../../../../lib/hooks/useMatchMedia';

import useStyles from './NewYearPage.styles';

const yFormula = 'max(0px, calc(1100px - 85vh))';

export const NewYearPage = () => {
  const classes = useStyles();

  const isMobile = useIsMobile(1150);

  const [scope, animate] = useAnimate<HTMLDivElement>();

  React.useEffect(() => {
    if (!scope.current) {
      return;
    }

    animate(scope.current, { top: 0, left: 0 });

    const target = isMobile
      ? {
          x: '-50%',
          y: yFormula,
          left: '50%',
          rotateZ: 0,
          scaleX: 1,
          bottom: 0,
          top: 'unset',
        }
      : {
          x: -340,
          y: 0,
          rotateZ: -25,
          scaleX: -1,
          top: '5%',
          left: 0,
        };

    animate(scope.current, target, {
      type: 'spring',
      duration: 1,
      bounce: 0.25,
    });
  }, [animate, isMobile, scope]);

  const handleClick = async () => {
    const audio = new Audio(
      'https://korekuta.ru/static/app/fluffypaww/yohoho-fluffypaww.wav'
    );

    audio.play();

    if (isMobile) {
      await animate(
        scope.current,
        { bottom: 50 },
        { duration: 1, type: 'spring' }
      );
      await animate(scope.current, { bottom: 0 });
      return;
    }

    await animate(scope.current, { y: -50 }, { duration: 1, type: 'spring' });
    await animate(scope.current, { y: 0 }, { duration: 1, type: 'spring' });
  };

  return (
    <>
      <Head>
        <title>Fluffypaww</title>
      </Head>
      <div className={clsx(classes.root, { [classes.mobile]: isMobile })}>
        <div className={classes.container}>
          <div className={classes.background}>
            <video
              src="https://korekuta.ru/static/app/fluffypaww/bg-ny.mp4"
              autoPlay
              muted
              loop
            />
          </div>
          <div ref={scope} className={classes.imageContainer}>
            <img
              src="https://korekuta.ru/static/app/fluffypaww/fluffypawwny.png"
              alt=""
              width={1000}
              height={1000}
              className={classes.fluffypaww}
            />
            <div className={classes.hat} onClick={handleClick}></div>
          </div>
          <div className={classes.wrapper}>
            <div className={classes.content}>
              <h1 className={classes.title}>Fluffypaww</h1>

              <div className={classes.listContainer}>
                <div className={classes.list}>
                  <div className={classes.entry}>
                    <Link
                      className={classes.link}
                      href="https://vk.com/fluffy_paww"
                      referrerPolicy="no-referrer"
                      rel="noopener noreferrer nofollow "
                      target="_blank"
                    >
                      <img
                        src="https://korekuta.ru/static/app/fluffypaww/vk-logo.svg"
                        alt=""
                      />
                    </Link>
                  </div>
                  <div className={classes.entry}>
                    <Link
                      className={classes.link}
                      href="https://discord.com/invite/WkVH5u9hCd"
                      referrerPolicy="no-referrer"
                      rel="noopener noreferrer nofollow "
                      target="_blank"
                    >
                      <RiDiscordLine />
                    </Link>
                  </div>
                  <div className={classes.entry}>
                    <Link
                      className={classes.link}
                      href="https://www.twitch.tv/fluffypaww"
                      referrerPolicy="no-referrer"
                      rel="noopener noreferrer nofollow "
                      target="_blank"
                    >
                      <RiTwitchLine />
                    </Link>
                  </div>
                  <div className={classes.entry}>
                    <Link
                      className={classes.link}
                      href="https://boosty.to/fluffypaww"
                      referrerPolicy="no-referrer"
                      rel="noopener noreferrer nofollow "
                      target="_blank"
                    >
                      <img
                        src="https://korekuta.ru/static/app/fluffypaww/boosty-logo.svg"
                        alt=""
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
