import { throttle } from 'lodash';
import React from 'react';

export const useIsMobile = (breakpoint = 1000) => {
  const [isMobile, setIsMobile] = React.useState(false);

  const handleResize = throttle(() => {
    const isMatches = matchMedia(`(max-width: ${breakpoint}px)`).matches;

    if (isMatches !== isMobile) {
      setIsMobile(isMatches);
    }
  }, 500);

  React.useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return isMobile;
};
