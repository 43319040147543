import clsx from 'clsx';
import { motion, useAnimationControls } from 'framer-motion';
import Image from 'next/image';
import React from 'react';

import { useSetState } from '../hooks';
import { SwappingImagesProps } from './SwappingImages.props';
import { State } from './SwappingImages.types';

import useStyles from './SwappingImages.styles';

const SwappingImages: React.FC<SwappingImagesProps> = ({ className, imgs }) => {
  const classes = useStyles();

  const [state, setState] = useSetState<State>({
    imageIdx: 0,
    nextImageIdx: 1,
  });

  const controls = useAnimationControls();

  const performImageRotation = React.useCallback(async () => {
    await controls.start({ opacity: 0 }, { duration: 2 });

    setState((prevState) => ({
      imageIdx: prevState.nextImageIdx,
    }));

    await controls.start({ opacity: 1 }, { delay: 2, duration: 0 });

    setState((prevState) => ({
      nextImageIdx:
        prevState.nextImageIdx >= imgs.length - 1
          ? 0
          : prevState.nextImageIdx + 1,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps -- prevState is used
  }, []);

  React.useEffect(() => {
    const timerId = setInterval(performImageRotation, 6000);

    return () => {
      clearInterval(timerId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- on mount only
  }, []);

  return (
    <div className={clsx(classes.root, className)}>
      <Image
        alt=""
        className={classes.img}
        src={imgs[state.nextImageIdx]}
        width={1920}
        height={1080}
      />
      <motion.div className={classes.imgContainer} animate={controls}>
        <Image
          className={classes.img}
          alt=""
          src={imgs[state.imageIdx]}
          width={1920}
          height={1080}
        />
      </motion.div>
    </div>
  );
};

export default SwappingImages;
