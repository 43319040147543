import Link from 'next/link';
import { FaDiscord, FaVk, FaYoutube } from 'react-icons/fa';

import { DOMAIN } from '../../../../../const';

import useStyles from './Footer.styles';

const Footer = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.root}>
        <Link
          className={classes.link}
          href="https://vk.com/metrocrafting"
          referrerPolicy="no-referrer"
          rel="noopener noreferrer nofollow "
          target="_blank"
        >
          <FaVk className={classes.icon} size={30} />
        </Link>
        <Link
          className={classes.link}
          href="https://discord.com/invite/S4xufdE"
          referrerPolicy="no-referrer"
          rel="noopener noreferrer nofollow "
          target="_blank"
        >
          <FaDiscord className={classes.icon} size={30} />
        </Link>
        <Link
          className={classes.link}
          href="https://www.youtube.com/MetroCrafting"
          referrerPolicy="no-referrer"
          rel="noopener noreferrer nofollow "
          target="_blank"
        >
          <FaYoutube className={classes.icon} size={30} />
        </Link>
      </div>
      <Link
        className={classes.link}
        href={`http://${DOMAIN}`}
        referrerPolicy="no-referrer"
        rel="noopener noreferrer nofollow "
        target="_blank"
      >
        Created by KingoSawada
      </Link>
    </div>
  );
};

export default Footer;
