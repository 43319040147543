import { createUseStyles } from 'react-jss';

export default createUseStyles(
  {
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      appearance: 'none',

      borderRadius: 5,

      padding: [10, 20],
      margin: 0,

      color: 'black',
      border: 'none',
      background: 'rgba(255, 255, 255, 0.75)',

      fontWeight: 500,
      fontSize: 20,

      '&:hover': {
        cursor: 'pointer',
      },

      '&:disabled': {
        opacity: 0.5,
        cursor: 'not-allowed',
      },
    },

    icon: {
      '$button &': {
        marginRight: 12,
      },
    },
  },
  { name: 'Button' }
);
