import { createUseStyles } from 'react-jss';

export default createUseStyles(
  {
    root: {
      position: 'absolute',
      left: 0,
      top: 0,

      width: '100vw',
      height: '100vh',

      zIndex: 4,
    },

    overlay: {
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, 0.25)',

      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  { name: 'Modal' }
);
