import React from 'react';

type Patch<T> = Partial<T> | ((prevState: T) => Partial<T>);

const useSetState = <T extends object>(
  initialState: T = {} as T
): [T, (patch: Patch<T>) => void] => {
  const [state, set] = React.useState<T>(initialState);
  const setState = React.useCallback((patch: Patch<T>) => {
    set((prevState) =>
      Object.assign(
        {},
        prevState,
        patch instanceof Function ? patch(prevState) : patch
      )
    );
  }, []);

  return [state, setState];
};

export default useSetState;
