import { createUseStyles } from 'react-jss';

export default createUseStyles(
  {
    container: {
      marginTop: 'auto',

      display: 'flex',
      flexDirection: 'column',
    },

    root: {
      padding: 20,

      display: 'grid',
      gridAutoFlow: 'column',
      gap: 20,
    },

    link: {
      color: 'white',
    },

    icon: {
      transition: 'transform 1s ease',

      '$link &:hover': {
        transform: 'scale(2)',
      },
    },
  },
  { name: 'Footer' }
);
