import { motion } from 'framer-motion';
import Head from 'next/head';
import Image from 'next/image';
import React from 'react';
import { AiOutlineWarning } from 'react-icons/ai';
import { FaLock } from 'react-icons/fa';

import { Button } from '../../../../components/Button';
import { SwappingImages } from '../../../../components/SwappingImages';
import resizeImage from '../../../../components/resize';
import { Footer } from './internals/Footer';
import { RegistrationModal } from './internals/RegistrationModal';

import useStyles from './MetroCraftingPage.styles';

export const LS_KEY = 'METROCRAFTING_IS_REGISTERED;';

const MetroCraftingPage = () => {
  const classes = useStyles();

  const [isRegistrated, setIsRegistrated] = React.useState(false);

  const [openRegistrationModal, setOpenRegistrationModal] =
    React.useState(false);

  const handleRegistrationClick = () => {
    setOpenRegistrationModal(true);
  };

  const handleRegistrationModalClose = () => {
    setOpenRegistrationModal(false);
    setIsRegistrated(Boolean(localStorage.getItem(LS_KEY)));
  };

  React.useEffect(() => {
    setIsRegistrated(Boolean(localStorage.getItem(LS_KEY)));
  }, []);

  return (
    <div className={classes.root}>
      <Head>
        <title>MetroCrafting</title>
        <link
          rel="icon"
          href="https://korekuta.ru/static/app/metrocrafting_favicon.ico"
        />
      </Head>
      <SwappingImages
        className={classes.background}
        imgs={[
          resizeImage('https://korekuta.ru/static/app/metrocrafting/0.png'),
          resizeImage('https://korekuta.ru/static/app/metrocrafting/1.png'),
          resizeImage('https://korekuta.ru/static/app/metrocrafting/2.png'),
          resizeImage('https://korekuta.ru/static/app/metrocrafting/3.png'),
        ]}
      />

      <div className={classes.body}>
        <Image
          className={classes.logo}
          src="https://korekuta.ru/static/app/metrocrafting/logo.png"
          alt="logo"
          width={799}
          height={307}
          priority
        />
        <RegistrationModal
          open={openRegistrationModal}
          onClose={handleRegistrationModalClose}
        />
        <div className={classes.buttons}>
          <Button
            icon={isRegistrated ? <FaLock /> : null}
            onClick={handleRegistrationClick}
            disabled={isRegistrated}
          >
            Регистрация
          </Button>
          <Button icon={<FaLock />} disabled>
            Авторизация
          </Button>
        </div>

        <div className={classes.warningContainer}>
          {isRegistrated ? (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              className={classes.warning}
            >
              <AiOutlineWarning size={50} />
              <p>
                Вы уже зарегестрировались!
                <br /> Ожидайте новостей или письма на почту!
              </p>
            </motion.div>
          ) : null}

          <div className={classes.warning}>
            <AiOutlineWarning size={50} />
            <p>
              Авторизация временно недоступна.
              <br />
              Следите за новостями в социальных сетях ниже!
            </p>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default MetroCraftingPage;
