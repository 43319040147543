import axios from 'axios';
import { Formik, Field, Form, ErrorMessage, FormikHelpers } from 'formik';
import * as yup from 'yup';

import { Button } from '../../../../../../components/Button';
import { Modal } from '../../../../../../components/Modal';
import { LS_KEY } from '../../MetroCraftingPage';
import { RegistrationModalProps } from './RegistrationModal.props';

import useStyles from './RegistrationModal.styles';

interface FormValues {
  nickname: string;
  password: string;
  repeatPassword: string;
  email: string;
}

const requiredMessage = 'Обязательное поле';
const minLengthMessage = 'Минимальная длинна 3 символа';
const maxLengthMessage = 'Максимальная длинна 3 символа';

const schema = yup.object().shape({
  nickname: yup
    .string()
    .required(requiredMessage)
    .min(3, minLengthMessage)
    .max(32, maxLengthMessage),
  password: yup
    .string()
    .required(requiredMessage)
    .min(3, minLengthMessage)
    .max(32, maxLengthMessage),
  repeatPassword: yup
    .string()
    .required(requiredMessage)
    .oneOf([yup.ref('password'), null], 'Пароли не совпадают'),
  email: yup
    .string()
    .required(requiredMessage)
    .min(3, minLengthMessage)
    .max(32, maxLengthMessage),
});

const RegistrationModal: React.FC<RegistrationModalProps> = ({
  open,
  onClose,
}) => {
  const classes = useStyles();

  const handleSubmit = async (
    values: FormValues,
    helpers: FormikHelpers<FormValues>
  ) => {
    axios
      .post(
        'https://metrocrafting.korekuta.ru/api/proxy/user/register',
        {
          nickname: values.nickname,
          password: values.password,
          email: values.email,
        },
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
          },
        }
      )
      .then((r) => {
        helpers.setStatus(r.data.success ? 'success' : 'error');
        localStorage.setItem(LS_KEY, '1');
      })
      .catch(() => {
        helpers.setStatus('error');
      })
      .finally(() => {
        helpers.setSubmitting(false);
      });
  };

  return (
    <Modal open={open} onClose={onClose} contentClassName={classes.content}>
      <div className={classes.root}>
        <h1 className={classes.header}>Регистрация</h1>
        <Formik<FormValues>
          initialValues={{
            nickname: '',
            password: '',
            repeatPassword: '',
            email: '',
          }}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, isValid, status }) => (
            <Form>
              <div className={classes.block}>
                <p className={classes.label}>Логин</p>
                <Field
                  className={classes.input}
                  id="nickname"
                  name="nickname"
                  placeholder="Логин"
                />
                <ErrorMessage
                  name="nickname"
                  component="p"
                  className={classes.errorMessage}
                />
              </div>
              <div className={classes.block}>
                <p className={classes.label}>Пароль</p>
                <Field
                  className={classes.input}
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Пароль"
                />
                <ErrorMessage
                  name="password"
                  component="p"
                  className={classes.errorMessage}
                />
              </div>
              <div className={classes.block}>
                <p className={classes.label}>Повторите пароль</p>
                <Field
                  className={classes.input}
                  id="repeatPassword"
                  name="repeatPassword"
                  type="password"
                  placeholder="Повторите пароль"
                />
                <ErrorMessage
                  name="repeatPassword"
                  component="p"
                  className={classes.errorMessage}
                />
              </div>
              <div className={classes.block}>
                <p className={classes.label}>Почта</p>
                <Field
                  className={classes.input}
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Почта"
                />
                <ErrorMessage
                  name="email"
                  component="p"
                  className={classes.errorMessage}
                />
              </div>
              <p className={classes.message}>
                {status === 'success' ? 'Регистрация прошла успешно!' : null}
                {status === 'error'
                  ? 'Произошла ошибка при регистрации. Попробуйте позже!'
                  : null}
              </p>
              <Button
                type="submit"
                className={classes.submit}
                disabled={isSubmitting || !isValid || status === 'success'}
              >
                Зарегистрироваться
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default RegistrationModal;
