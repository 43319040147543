const resizeImage = (
  url: string,
  width: number = 1920,
  height: number = 1080
) => {
  return `${
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:3000'
      : 'https://korekuta.ru'
  }/api/img/resize?width=${width}&height=${height}&url=${encodeURIComponent(
    url
  )}`;
};

export default resizeImage;
