import { motion } from 'framer-motion';

import { useFadeOut } from '../hooks';
import { ModalProps } from './Modal.props';

import useStyles from './Modal.styles';

const Modal: React.FC<ModalProps> = ({
  open,
  children,
  onClose,
  contentClassName,
}) => {
  const classes = useStyles();

  const { canUnmount, controls } = useFadeOut(open);

  const handleOnClickOverlay = () => {
    onClose?.();
  };

  const handleOnClickContent = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  if (canUnmount) {
    return null;
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={controls}
      className={classes.root}
    >
      <div onClick={handleOnClickOverlay} className={classes.overlay}>
        <div onClick={handleOnClickContent} className={contentClassName}>
          {children}
        </div>
      </div>
    </motion.div>
  );
};

export default Modal;
