import { createUseStyles } from 'react-jss';

import Media from '../../../../components/media';

export default createUseStyles(
  {
    root: {
      width: '100vw',
      minHeight: '100vh',
      height: '100%',

      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      background: 'black',

      color: 'white',
    },

    background: {
      position: 'absolute',
      left: 0,
      top: 0,

      width: '100vw',
      height: '100vh',

      filter: 'blur(5px)',
    },

    body: {
      zIndex: 2,
      width: '100%',
      minHeight: '100vh',
      height: '100%',

      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',

      padding: [20, 0],
    },

    logo: {
      marginTop: 'auto',

      maxWidth: '90%',
      width: 'auto',
      height: 'auto',

      objectFit: 'contain',
    },

    buttons: {
      marginTop: 50,

      display: 'grid',
      gridAutoFlow: 'column',
      gap: 40,
    },

    warningContainer: {
      maxWidth: '90%',
    },

    warning: {
      marginTop: 50,

      background: 'rgba(255, 220, 210, 0.5)',

      padding: 20,
      borderRadius: 5,

      display: 'grid',
      gridAutoFlow: 'column',
      gap: 10,

      alignItems: 'center',
    },

    footer: {
      marginTop: 'auto',

      padding: 20,

      display: 'grid',
      gridAutoFlow: 'column',
      gap: 20,
    },

    link: {
      color: 'white',
    },

    icon: {
      transition: 'transform 1s ease',

      '$link &:hover': {
        transform: 'scale(2)',
      },
    },

    [Media.Tablet]: {
      logo: {
        width: '90%',
        height: 'auto',
      },
    },

    [Media.Mobile]: {
      logo: {
        width: '75%',
        height: 'auto',
      },

      buttons: {
        gridAutoFlow: 'row',
      },
    },
  },
  { name: 'MetroCrafting' }
);
