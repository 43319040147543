import { createUseStyles } from 'react-jss';

import Media from '../../../../../../components/media';
import theme from '../../../../../../theme';

export default createUseStyles(
  {
    content: {},

    root: {
      background: 'rgba(64, 64, 64, 0.9)',

      padding: 50,
      borderRadius: 5,
    },

    header: {
      fontSize: 42,
      marginTop: 0,
    },

    block: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 10,
    },

    label: {
      margin: [5, 0],
    },

    input: {
      appearance: 'none',
      border: 'none',
      borderRadius: 5,

      padding: 5,
    },

    errorMessage: {
      color: theme.negativeLight,

      margin: [5, 0],

      fontSize: 12,
    },

    submit: {
      marginTop: 50,

      display: 'flex',

      width: '100%',
    },

    message: {
      marginTop: 50,
      marginBottom: 0,
    },

    [Media.Mobile]: {
      root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',

        background: 'rgba(64, 64, 64 0.9)',

        width: '100vw',
        padding: 20,
      },

      content: {
        marginTop: 'auto',
      },
    },
  },
  { name: 'RegistrationModal' }
);
