import { Pacifico } from 'next/font/google';
import { createUseStyles } from 'react-jss';

const pacifico = Pacifico({
  subsets: ['latin'],
  style: 'normal',
  preload: true,
  fallback: ['SF Mono', 'Consolas'],
  adjustFontFallback: true,
  display: 'block',
  weight: '400',
});

export default createUseStyles(
  {
    root: {
      position: 'relative',

      width: '100vw',
      height: '100vh',

      display: 'flex',
      flexDirection: 'column',

      overflow: 'hidden',

      background: 'rgba(0, 64, 255, 0.25)',

      fontFamily: pacifico.style.fontFamily,
    },

    container: {
      position: 'relative',

      width: '100%',
      height: '100%',
      maxWidth: 1280,

      margin: [0, 'auto'],

      display: 'flex',
      flexDirection: 'column',

      overflow: 'hidden',
    },

    background: {
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,

      filter: 'blur(20px)',
      background:
        'url(https://korekuta.ru/static/app/fluffypaww/background.jpg)',

      transform: 'scale(1.1)',

      '& video': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
    },

    imageContainer: {
      position: 'absolute',

      zIndex: 2,
      pointerEvents: 'none',

      top: '5%',
      left: '0',
      transform: 'translateX(-1000px) rotateZ(0) scaleX(-1)',
    },

    fluffypaww: {},

    hat: {
      position: 'absolute',
      top: 100,
      right: 250,

      width: 370,
      height: 256,

      cursor: 'pointer',
      pointerEvents: 'all',
    },

    wrapper: {
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      zIndex: 1,

      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    content: {
      flex: 1,
      maxWidth: 600,
      padding: 40,

      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      justifyContent: 'center',

      background: 'rgba(0, 64, 128, 0.5)',
      borderRadius: 12,

      transition: 'padding 0.5s ease',
    },

    title: {
      fontSize: 'min(16vw, 64px)',
      fontWeight: 100,

      color: 'white',

      margin: [0, 'auto', 50, 'auto'],

      fontFamily: pacifico.style.fontFamily,
    },

    listContainer: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
    },

    list: {
      display: 'flex',
      flex: 1,
      justifyContent: 'space-around',

      maxWidth: 450,

      padding: [0, 10],
      // margin: [0, 'auto'],
    },

    entry: {
      fontSize: 'min(14vw, 64px)',
      maxHeight: 64,

      color: 'white',

      transition: 'transform 0.25s ease, filter 0.25s ease',

      '$root:not($mobile) &:hover': {
        transform: 'scale(1.25)',
      },

      '& img': {
        width: '1em',
        height: '1em',
      },
    },

    link: {
      display: 'flex',

      textDecoration: 'none',
      color: 'white',

      maxHeight: 64,
    },

    mobile: {
      display: 'flex',

      '& $wrapper': {
        alignItems: 'flex-start',
      },

      '& $content': {
        marginTop: 50,
      },

      '& $imageContainer': {
        top: 'unset',
        bottom: 0,
      },
    },

    '@media(max-width: 600px)': {
      content: {
        borderRadius: 0,
        width: '100%',

        padding: [40, 10],
      },
    },
  },
  { name: 'FluffypawwPage' }
);
