import { createUseStyles } from 'react-jss';

export default createUseStyles(
  {
    root: {
      position: 'relative',

      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    imgContainer: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      overflow: 'hidden',
    },

    img: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  { name: 'SwappingImages' }
);
