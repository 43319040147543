import clsx from 'clsx';

import { ButtonProps } from './Button.props';

import useStyles from './Button.styles';

const Button: React.FC<ButtonProps> = ({
  className,
  children,
  icon,
  ...restProps
}) => {
  const classes = useStyles();

  return (
    <button className={clsx(classes.root, className)} {...restProps}>
      {icon ? <div className={classes.icon}>{icon}</div> : null}
      {children}
    </button>
  );
};

export default Button;
